import '@elements/content-media.css'

const setupSlider = async (slider) => {
  import('swiper/css')
  import('swiper/css/autoplay')
  import('swiper/css/effect-fade')

  const { default: Swiper, Autoplay, EffectFade, Pagination } = await import('swiper')

  Swiper.use([Autoplay, EffectFade, Pagination])

  const container = slider.querySelector('.swiper')
  const numSlides = slider.querySelectorAll('.swiper-slide').length

  new Swiper(container, {
    slidesPerView: 1,
    preloadImages: false,
    simulateTouch: false,
    speed: 1000,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: slider.querySelector('.slider-pagination'),
      clickable: true,
    },
    loop: true,
    loopedSlides: numSlides,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
  })
}

const setupSliders = () => {
  document.querySelectorAll('.element-contentmedia').forEach((el) => {
    const slider = el.querySelector('.content-media-slider')
    const numSlides = slider.querySelectorAll('.swiper-slide').length

    if (numSlides > 1) {
      setupSlider(slider)
    }
  })
}

setupSliders()

export { setupSliders }
